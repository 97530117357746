import React, { ReactNode, useState } from 'react';
import VoucherContext from './voucherContext';
import {
  CreateVoucherDataMoMo,
  CreateVoucherDataNGN,
  CreateVoucherDataUSD,
} from '../api/models/voucher';

const VoucherProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<
    | CreateVoucherDataNGN
    | CreateVoucherDataUSD
    | CreateVoucherDataMoMo
    | undefined
  >();

  return (
    <VoucherContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </VoucherContext.Provider>
  );
};

export default VoucherProvider;
