import React, { useContext } from 'react';
import { OfframpPaymentData } from '../components/Offramp/api/payment';
import { RateProps } from '../components/Offramp/api/rate';

export interface Store {
  error: boolean;
  setError?: Function;
  state?: OfframpPaymentData | undefined;
  setState?: Function;
  rate: RateProps | undefined;
  setRate: Function;
  merchant?: any;
  setMerchant?: Function;
}

const store: Store = {
  state: undefined,
  setState: () => {},
  error: false,
  setError: () => {},
  rate: undefined,
  setRate: () => {},
  merchant: undefined,
  setMerchant: () => {},
};

const OfframpPaymentContext = React.createContext(store);
OfframpPaymentContext.displayName = 'Offramp Payment Context';
export const useOfframpPaymentContext = () => useContext(OfframpPaymentContext);
export default OfframpPaymentContext;
