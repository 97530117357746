import React from 'react';
import {
  CreateVoucherDataMoMo,
  CreateVoucherDataNGN,
  CreateVoucherDataUSD,
} from '../api/models/voucher';
export interface Store {
  state?: CreateVoucherDataNGN | CreateVoucherDataUSD | CreateVoucherDataMoMo;
  setState?: Function;
}
const store: Store = {};

const VoucherContext = React.createContext(store);
VoucherContext.displayName = 'Voucher Context';

export default VoucherContext;
