import React, { ReactNode, useState } from 'react';
import OfframpPaymentContext from './offrampPaymentContext';
import { OfframpPaymentData } from '../components/Offramp/api/payment';
import { RateProps } from '../components/Offramp/api/rate';

const OfframpPaymentProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<OfframpPaymentData | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [rate, setRate] = useState<RateProps | undefined>();
  const [merchant, setMerchant] = useState<any>(null);
  return (
    <OfframpPaymentContext.Provider
      value={{
        state,
        setState,
        error,
        setError,
        rate,
        setRate,
        merchant,
        setMerchant,
      }}
    >
      {children}
    </OfframpPaymentContext.Provider>
  );
};

export default OfframpPaymentProvider;
