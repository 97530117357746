import { Service } from './LandingPage';

export interface currencyDetailProps {
  label: string;
  value: string;
  network: string;
  logo: string;
}
export type SupportedCurrencies = 'cusd' | 'ngn' | 'ghs' | 'usdt' | 'usdc';
export type CurrencyInfo = {
  [key in SupportedCurrencies]: currencyDetailProps;
};

export const currencyInfo: CurrencyInfo = {
  cusd: {
    label: 'cUSD',
    value: 'CUSD',
    network: 'CELO',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1712233194/coinprofile%202.0/cryptocurrency/cUSD-color_1_med0st.svg',
  },
  ngn: {
    label: 'NGN',
    value: 'NGN',
    network: 'FIAT',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1718025683/coinprofile%20web/currency%20logos/NigeriaFlag_ss5mf0.png',
  },
  ghs: {
    label: 'GHS',
    value: 'GHS',
    network: 'FIAT',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1723384958/coinprofile%202.0/cryptocurrency/ghanaLogo_n7ri5e.svg',
  },
  usdt: {
    label: 'USDT',
    value: 'USDT',
    network: 'CELO',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
  },
  usdc: {
    label: 'USDC',
    value: 'USDC',
    network: 'CELO',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
  },
};

export const PAYMENT_TYPE_BANK = 'bank';

export const COUNTRY_NG = 'NG';

export const BUSINESS_ID = 'remittance';

export const STORED_USER = 'offrampUser';

export const CELO_NETWORK = 'CELO';

export const supportedSellCurrenciesLogo: string[] = [
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/cUSD_bjukjd.webp',
];

export const supportedBuyCurrenciesLogo: string[] = [
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/cUSD_bjukjd.webp',
  'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/BTC_k7agjr.webp',
];

export const serviceList = [
  {
    name: 'onramp' as Service,
    title: 'Buy Crypto',
    modeOfPayment: 'Bank transfer',
    supportedCurrencies: supportedBuyCurrenciesLogo,
  },
  {
    name: 'offramp' as Service,
    title: 'Sell Crypto',
    modeOfPayment: 'Bank transfer',
    supportedCurrencies: supportedSellCurrenciesLogo,
  },
];

export const partners = [
  {
    name: 'Minipay',
    link: 'https://www.opera.com/products/minipay',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1720696298/Ventogram/merchant-logos/minipay_fh8p9y.svg',
    supportedCurrencies: [
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/cUSD_bjukjd.webp',
    ],
  },
  {
    name: 'Cenoa',
    link: 'https://www.cenoa.com/',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1720696297/Ventogram/merchant-logos/cenoa_ujionv.svg',
    supportedCurrencies: [
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
    ],
  },
  {
    name: 'Onrampmoney',
    link: 'https://onramp.money/',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1720696321/Ventogram/merchant-logos/onrampmoney_hatlf2.svg',
    supportedCurrencies: [
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/BTC_k7agjr.webp',
    ],
  },
  {
    name: 'Coinprofile',
    link: 'https://coinprofile.com/',
    logo: 'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1720696321/Ventogram/merchant-logos/coinprofile_fhcqq9.svg',
    supportedCurrencies: [
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDT_f3tsc3.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/USDC_yyvg3a.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/cUSD_bjukjd.webp',
      'https://res.cloudinary.com/payourse-technologies-inc/image/upload/v1670678177/coinprofile%20web/currency%20logos/BTC_k7agjr.webp',
    ],
  },
];
